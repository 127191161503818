import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

const axiosInstance = axios.create({
  // baseURL: "http://localhost:5000/api/v1/user",
  baseURL: "https://admin.vaultrock.org/api/v1/user",
});

const setAuthToken = (token: any) => {
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
};

// const useUnauthorizedInterceptor = () => {
//   const navigate = useNavigate();
//   const location = useLocation();

//   axiosInstance.interceptors.response.use(
//     (response) => response,
//     (error) => {
//       if (error.response && error.response.status === 401) {
//         // Unauthorized request, navigate to the login screen
//         navigate("/login", { state: { from: location } });
//       }
//       return Promise.reject(error);
//     }
//   );
// };

export { axiosInstance, setAuthToken };
