import { createContext, ReactNode, useEffect, useReducer } from "react";
import { Product } from "../react-query/hooks/useGetProducts";

export const SavedContext = createContext<{
  state: SavedState;
  dispatch: React.Dispatch<SavedAction>;
}>({
  state: { Saved: [] },
  dispatch: () => {},
});
type product = {
  quantity: number;
} & Product;
type SavedAction =
  | {
      type: "ADD_PRODUCT";
      payload: product;
    }
  | {
      type: "REMOVE_PRODUCT";
      payload: number;
    }
  | {
      type: "REMOVE_ALL_PRODUCT";
    }
  | {
      type: "INCREASE_QUANTITY";
      payload: number;
    }
  | {
      type: "DECREASE_QUANTITY";
      payload: number;
    }
  | {
      type: "LOAD_SAVED_PRODUCTS";
      payload: product[];
    };

type SavedState = {
  Saved: product[];
};
const reducer = (state: SavedState, action: SavedAction) => {
  switch (action.type) {
    case "ADD_PRODUCT":
      const existingSaved = state.Saved.find(
        (item) => item.id === action.payload.id
      );
      if (existingSaved) {
        return {
          ...state,
          Saved: [...state.Saved].map((item) =>
            item.id === existingSaved.id
              ? { ...item, quantity: item.quantity + action.payload?.quantity }
              : item
          ),
        };
      } else {
        return {
          ...state,
          Saved: [...state.Saved, { ...action.payload }],
        };
      }
    case "REMOVE_PRODUCT":
      return {
        ...state,
        Saved: [...state.Saved].filter((item) => item.id !== action.payload),
      };
    case "INCREASE_QUANTITY":
      return {
        ...state,
        Saved: [...state.Saved].map((item) =>
          item.id === action.payload
            ? { ...item, quantity: item.quantity + 1 }
            : item
        ),
      };
    case "DECREASE_QUANTITY":
      return {
        ...state,
        Saved: [...state.Saved].map((item) =>
          item.id === action.payload
            ? { ...item, quantity: item.quantity - 1 }
            : item
        ),
      };
    case "REMOVE_ALL_PRODUCT":
      return {
        ...state,
        Saved: [],
      };
    case "LOAD_SAVED_PRODUCTS":
      return {
        ...state,
        Saved: action.payload,
      };
    default:
      return state;
  }
};
const SavedContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, { Saved: [] });
  // Load saved products from localStorage on component mount
  useEffect(() => {
    const savedProducts = JSON.parse(
      localStorage.getItem("savedProducts") || "[]"
    );
    dispatch({ type: "LOAD_SAVED_PRODUCTS", payload: savedProducts });
  }, []);

  // Save changes to saved products in localStorage whenever state changes
  useEffect(() => {
    localStorage.setItem("savedProducts", JSON.stringify(state.Saved));
  }, [state.Saved]);
  return (
    <SavedContext.Provider value={{ state, dispatch }}>
      {children}
    </SavedContext.Provider>
  );
};
export default SavedContextProvider;
