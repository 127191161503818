import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import SavedContextProvider from "./contextApi/SavedContext";
import NotificationProvider from "./utils/Notification";
import { AuthProvider } from "./contextApi/NewAuthContext";
const query = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={query}>
      <Router>
        <NotificationProvider>
          <SavedContextProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </SavedContextProvider>
        </NotificationProvider>
      </Router>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
