// AuthContext.ts
import Cookies from "js-cookie";
import { createContext, useContext, useState, ReactNode } from "react";

interface AuthContextType {
  token: string | null;
  login: (userToken: string) => void;
  logout: () => void;
}

export const NewAuthContext = createContext<AuthContextType | undefined>(
  undefined
);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [token, setToken] = useState<string | null>(
    Cookies.get("authToken") as string
  );

  const login = (userToken: string) => {
    // localStorage.setItem("authToken", userToken);
    Cookies.set("authToken", userToken, { expires: 1 });
    setToken(userToken);
  };

  const logout = () => {
    // localStorage.removeItem("authToken");
    Cookies.remove("authToken");
    setToken(null);
  };

  return (
    <NewAuthContext.Provider value={{ token, login, logout }}>
      {children}
    </NewAuthContext.Provider>
  );
}
