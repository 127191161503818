import { Outlet, Navigate, useLocation } from "react-router-dom";
import adminAuthenticated from "./adminAuthenticated";

export default function AdminProtectedRoute() {
  const location = useLocation();

  return adminAuthenticated() ? (
    <Outlet />
  ) : (
    <Navigate to="/adminlogin" state={{ from: location }} />
  );
}
