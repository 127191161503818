import { lazy } from "react";
import { PAGES } from "./pages";
const AdminDashboard = lazy(() => import("../pages/AdminDashboard"));

const protected_route = [
  {
    id: 17,
    name: "admin-dashboard",
    path: PAGES.ADMINDASHBOARD,
    component: AdminDashboard,
  },
];

export default protected_route;
