import { lazy } from "react";
import { PAGES } from "./pages";

const Home = lazy(() => import("../pages/Home"));
const Contact = lazy(() => import("../pages/Contact"));
const About = lazy(() => import("../pages/About"));
const Services = lazy(() => import("../pages/Services"));
const NotFound = lazy(() => import("../pages/NotFound"));
const ProductDetails = lazy(() => import("../pages/ProductDetails"));
const Cart = lazy(() => import("../pages/Cart"));
const Login = lazy(() => import("../pages/Login"));
const ForgetPassword = lazy(() => import("../pages/ForgetPassword"));
const Register = lazy(() => import("../pages/Register"));

const Payment = lazy(() => import("../pages/Payment"));
const Tracking = lazy(() => import("../pages/Tracking"));
const TrackingDetails = lazy(() => import("../pages/TrackingDetails"));
const Privacy = lazy(() => import("../pages/Privacy"));
const Shipping = lazy(() => import("../pages/Shipping"));
const TermOfUse = lazy(() => import("../pages/TermOfUse"));
const Payment_policy = lazy(() => import("../pages/Payment_policy"));
const VERIFICATION_SCREEN = lazy(() => import("../pages/VerificationScreen"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));

const basic_route = [
  {
    id: 1,
    name: "Home",
    path: PAGES.ROOT,
    component: Home,
  },
  {
    id: 2,
    name: "About",
    path: PAGES.ABOUT,
    component: About,
  },
  {
    id: 3,
    name: "Contact",
    path: PAGES.CONTACT,
    component: Contact,
  },
  {
    id: 4,
    name: "Services",
    path: PAGES.SERVICES,
    component: Services,
  },
  {
    id: 5,
    name: "product__details",
    path: PAGES.PRODUCT_DETAILS,
    component: ProductDetails,
  },
  {
    id: 6,
    name: "cart",
    path: PAGES.CART,
    component: Cart,
  },
  {
    id: 8,
    name: "login page",
    path: PAGES.LOGIN,
    component: Login,
  },
  {
    id: 9,
    name: "register",
    path: PAGES.REGISTER,
    component: Register,
  },

  {
    id: 12,
    name: "payment",
    path: PAGES.PAYMENT,
    component: Payment,
  },
  {
    id: 11,
    name: "tracking",
    path: PAGES.TRACKING,
    component: Tracking,
  },
  {
    id: 12,
    name: "tracking-details",
    path: PAGES.TRACKING_DETAILS,
    component: TrackingDetails,
  },
  {
    id: 12,
    name: "privacy",
    path: PAGES.PRIVACY,
    component: Privacy,
  },
  {
    id: 13,
    name: "term_of_use",
    path: PAGES.TERM_OF_USE,
    component: TermOfUse,
  },
  {
    id: 14,
    name: "payment_policy",
    path: PAGES.PAYMENT_POLICY,
    component: Payment_policy,
  },
  {
    id: 15,
    name: "shipping",
    path: PAGES.SHIPPING,
    component: Shipping,
  },
  {
    id: 18,
    name: "forget-password",
    path: PAGES.FORGET_PASSWORD,
    component: ForgetPassword,
  },
  {
    id: 19,
    name: "verification-screen",
    path: PAGES.VERIFICATION_SCREEN,
    component: VERIFICATION_SCREEN,
  },
  {
    id: 20,
    name: "confirm-password",
    path: PAGES.CONFIRM_PASSWORD,
    component: ResetPassword,
  },
  {
    id: 21,
    name: "not-found",
    path: PAGES.NotFound,
    component: NotFound,
  },
];

export default basic_route;
