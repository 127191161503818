import { useContext } from "react";
import jwt_decode from "jwt-decode";
import { NewAuthContext } from "../contextApi/NewAuthContext";
import Cookies from "js-cookie";

const useUserAuthentication = () => {
  const context = useContext(NewAuthContext);

  if (context?.token) {
    try {
      const decodedToken: any = jwt_decode(context?.token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        // localStorage.removeItem("authToken");
        Cookies.remove("authToken");
        return false;
      }

      return true;
    } catch (error) {
      console.error("Invalid token:", error);
    }
  }

  return false;
};

export default useUserAuthentication;
