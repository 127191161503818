import { lazy } from "react";
import { PAGES } from "./pages";
const Checkout = lazy(() => import("../pages/Checkout"));
const Account = lazy(() => import("../pages/Account"));
const Addresses = lazy(() => import("../pages/Addresses"));
const Confirmation = lazy(() => import("../pages/Confirmation"));

const protected_route = [
  {
    id: 7,
    name: "checkout",
    path: PAGES.CHECKOUT,
    component: Checkout,
  },
  {
    id: 10,
    name: "addresses",
    path: PAGES.ADDRESSES,
    component: Addresses,
  },
  {
    id: 8,
    name: "Account",
    path: PAGES.ACCOUNT,
    component: Account,
  },
  {
    id: 9,
    name: "confirmation",
    path: PAGES.CONFIRMATION,
    component: Confirmation,
  },
];

export default protected_route;
