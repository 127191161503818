import { Outlet, Navigate, useLocation } from "react-router-dom";
import useUserAuthentication from "./useUserAuthentication";

export default function ProtectedRoute() {
  const isAuthenticated = useUserAuthentication();

  const location = useLocation();

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
}
