export enum PAGES {
  ROOT = "/",
  CONTACT = "/contact",
  ABOUT = "/about",
  SERVICES = "/service",
  CART = "/cart",
  PRODUCT_DETAILS = "/products/:id",
  CHECKOUT = "/checkout",
  ACCOUNT = "/account",
  CONFIRMATION = "/confirmation",
  LOGIN = "/login",
  ADDRESSES = "account/addresses",
  PAYMENT = "/payment",
  REGISTER = "/register",
  TRACKING = "/track-parcel/courier",
  PRIVACY = "/privacy",
  TERM_OF_USE = "/term_of_use",
  SHIPPING = "/shipping",
  FORGET_PASSWORD = "/forget-password",
  PAYMENT_POLICY = "/payment_policy",
  ADMIN_LOGIN = "/adminlogin",
  ADMINDASHBOARD = "/admin/dashboard",
  TRACKING_DETAILS = "/track-parcel/courier/tracking_details",
  VERIFICATION_SCREEN = "/verification/reset-password/otp/:token",
  CONFIRM_PASSWORD = "/verification/renew-password",
  NotFound = "*",
}
