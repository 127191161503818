import "./styles/main.scss";
import "./css/animate.min.css";
import "./css/bootstrap.min.css";

import "./css/magnific-popup.min.css";
import "./css/meanmenu.min.css";
import "./css/nice-select.min.css";

import "./css/odometer.min.css";
import "./css/style.css";
import "./css/dark.css";

import "./css/responsive.css";
import "./css/login.css";
import "./css/tracker.css";
import "./css/flaticon.css";

import basic_route from "./router/data";
import protected_route from "./router/protected";
import adminProtected from "./router/adminProtected";
import { Route, Routes } from "react-router-dom";
import { Suspense, useEffect } from "react";
import ProtectedRoute from "./utils/ProtectedRoutes";
import Ripple from "./components/Ripple";
import AdminProtectedRoute from "./utils/AdminProtectedRoute ";
import { NewAuthContext } from "./contextApi/NewAuthContext";
import { useContext } from "react";
import { setAuthToken } from "./utils/axiosConfig";

function App() {
  const context = useContext(NewAuthContext);
  // useUnauthorizedInterceptor();
  useEffect(() => {
    if (context?.token) {
      setAuthToken(context?.token);
    }
  }, [context?.token]);

  return (
    <Routes>
      {basic_route?.map((route) => (
        <Route
          key={route?.id}
          path={route?.path}
          element={
            <Suspense fallback={<Ripple />}>
              <route.component />
            </Suspense>
          }
        />
      ))}
      <Route element={<ProtectedRoute />}>
        {protected_route?.map((route) => (
          <Route
            key={route?.id}
            path={route?.path}
            element={
              <Suspense fallback={<Ripple />}>
                <route.component />
              </Suspense>
            }
          />
        ))}
      </Route>
      <Route element={<AdminProtectedRoute />}>
        {adminProtected?.map((route) => (
          <Route
            key={route?.id}
            path={route?.path}
            element={
              <Suspense fallback={<Ripple />}>
                <route.component />
              </Suspense>
            }
          />
        ))}
      </Route>
    </Routes>
    //  <Home/>
  );
}

export default App;
